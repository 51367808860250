@import "../node_modules/scss-reset/src/scss/_reset.scss";

[data-theme],
.theme-light,
.theme-dark {
  background-color: var(--color-background);
  color: var(--color-foreground);
}

:root,
.theme-light,
[data-theme="light"] {
  --color-background: hsla(0, 0%, 100%, 1);
  --color-background-secondary: hsla(0, 0%, 96%, 1);
  --color-foreground: hsla(0, 0%, 24%, 1);
  --color-foreground-secondary: hsla(0, 0%, 40%, 1);
  --color-foreground-tertiary: hsla(0, 0%, 88%, 1);
  --color-foreground-accent: 220deg 100% 50%;
}

.theme-dark,
[data-theme="dark"] {
  --color-background: hsla(0, 0%, 8%, 1);
  --color-background-secondary: hsla(0, 0%, 12%, 1);
  --color-foreground: hsla(0, 0%, 80%, 1);
  --color-foreground-secondary: hsla(0, 0%, 60%, 1);
  --color-foreground-tertiary: hsla(0, 0%, 24%, 1);
  --color-foreground-accent: 48deg 100% 57%;
}
g {
  fill: rgb(255, 0, 200);
}
.floatingnav {
  position: fixed;
  display: flex;
  background-color: var(--color-background);
  justify-content: center;
  width: 100%;
  padding: 0 1em;
  height: 3em;
  margin: auto;
  bottom: 0;
  z-index: 100;

  border-top: 1px solid var(--color-foreground-tertiary);
  nav {
    justify-content: space-between;
  }
  a.homelink {
    background: var(--color-foreground-tertiary);
    background: linear-gradient(
      170deg,
      rgba(115, 119, 126, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );

    display: flex;
    position: absolute;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 50%;
    transform: translateY(-50%);
  }
  visibility: hidden;
}
.showit {
  visibility: visible;
}
.floatingnav svg.logo {
  width: 1.5em;
}

.secondnav--hidden {
  display: none;
}
.topbar nav {
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.topbar nav,
.floatingnav nav {
  padding: 0 0em;
  width: 100%;
  right: 0;
  display: flex;

  a {
    margin-left: 3em;
    text-align: center;
    font-size: 0.625em;
    line-height: 1em;
    font-family: "HelveticaNowDisplay";
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    color: var(--color-foreground);
    align-self: center;
  }
  a:first-child {
    margin-left: 0;
  }
}

html {
  font-size: 16px;
  background-color: var(--color-background);
}
a {
  color: var(--color-foreground);
  text-decoration: none;
}

section {
  padding: 8vh 0;
}

section.alternatebg {
  background-color: var(--color-background-secondary);
}

header {
  width: 100%;
  margin: auto;
  -webkit-box-align: center;

  .nav_menu {
    margin-left: auto;
    margin-right: 2%;
  }
  .topbar {
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin: auto;
    flex-direction: row;
    padding: 2%;
  }
}

figcaption {
  text-align: center;
}

body p {
  font-size: 0.875rem;
  font-family: $font_paragraph;
  line-height: 1.5;
}

figcaption {
  margin-top: 1em;
  margin-bottom: 2em;
  font-weight: 700;
  color: var(--color-foreground);
  font-size: 1.25em;
}

footer {
  padding-top: 2em;
  hr {
    background: var(--color-foreground-tertiary);
    margin-top: 0.5em;
    width: 100%;
  }

  hr:first-of-type {
    margin-top: 3em;
  }

  hr:last-of-type {
    margin-top: 1em;
  }
  a {
    color: var(--color-foreground-secondary);
  }
  p {
    font-weight: 500;
  }
  p:last-of-type {
    margin: 0;
  }

  margin-bottom: 0;
  .container {
    padding: 2em;
  }
}

h2,
h3,
h4,
h5 {
  font-family: $font_heading;
  line-height: 1.5;
  margin-bottom: 1em;
}

p:last-of-type {
  margin-bottom: 2em;
}
p {
  margin-bottom: 0.5em;
}

h3 {
  font-size: 2em;
  font-weight: 900;
  margin-bottom: 0.5em;

  color: var(--color-foreground);
}

.page_home .intro p {
  width: 72%;
}

.page_home .intro {
  height: 80vh;
}
h2 {
  font-size: 4vh;
  margin-bottom: 1vh;
}

.intro {
  min-height: 100%;
  -ms-flex-direction: row;
  flex-direction: row;
  text-align: left;
  padding: 4em 0;
  font-family: $font_heading;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  display: flex;

  hr {
    width: 8em;
    height: 0.25em;
    margin-bottom: 4em;
    background-color: var(--color-foreground-tertiary);
  }

  h1 {
    font-weight: 900;
    font-family: $font_title;
    font-size: calc(1em + 6vmin);
    color: var(--color-foreground);
    line-height: 1;
    letter-spacing: -0.2vh;
    margin-bottom: 3vh;
    &.page_home {
      font-size: 20vmin;
    }
  }

  h2 {
    font-family: $font_heading;

    font-weight: 800;
    font-size: calc(1em + 1vmin);
    line-height: 1.5;
    color: var(--color-foreground-secondary);
  }

  p {
    font-size: 1.5em;
    font-family: $font_heading;
    line-height: 1.4;
    color: var(--color-foreground-secondary);
    font-weight: 600;
  }
  p:first-child {
    margin-top: 0;
  }
  .company_logo {
    height: 100%;
  }

  .logo_container {
    height: 2em;
    margin-bottom: 1em;
    text-align: left;
  }
}

svg:hover {
  fill: $gradient_general;
}
.content {
  margin: auto;
  font-family: $font_paragraph;
  line-height: 150%;
  color: var(--color-foreground-secondary);
  font-weight: 400;
  p,
  h3,
  h4 {
    line-height: 1.5;
    margin: auto;
    margin-bottom: 0.5em;
  }
  h3 {
    font-size: calc(1em + 2vmin);
  }
  h4 {
    font-size: calc(1em + 1vmin);
    font-weight: 600;
    color: var(--color-foreground);
    margin-top: 1em;
  }
  p {
    font-size: 1.25em;
    line-height: 1.5;
  }
  p:last-of-type {
    margin-bottom: 2em;
  }
}

.content_flex {
  width: $width_desktop;
  margin: auto;
}
.content_grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2%;
  padding: 0 4%;
}

.highlighted {
  color: var(--color-foreground);
}
.highlighted.red {
  color: $color_red;
}

.grid_left {
  grid-column: 1 / span 6;
}
.grid_right {
  grid-column: 7 / span 12;
}
.gradient {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  display: inline-block;
}
.gradient.bby {
  background: $gradient_bby;
  background-attachment: fixed;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient.mastercard {
  background: $gradient_mastercard;
  background-attachment: fixed;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  svg {
    color: #cf4500;
    width: 0.75em;
    height: 0.75em;
  }
}
.gradient.jjoo {
  background: rgb(115, 119, 126);
  background: linear-gradient(
    170deg,
    rgba(115, 119, 126, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.logo:hover {
  fill: rgb(255, 0, 200);
}
.logo {
  fill: var(--color-foreground);
}

.gradient.catalx {
  background: $gradient_catalx;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient:hover,
a:hover,
.activeNav {
  background: rgb(255, 0, 200);
  background: $gradient_general;
  display: inline-block;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.otherlinks {
  a {
    font-family: $font_heading;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.5em;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    display: block;
    color: var(--color-foreground-secondary);
  }
  svg {
    line-height: 1.5em;
    height: 1.5em;
    padding-top: 0.75em;
    margin-right: 0.5em;
  }
}

.rapsheet {
  grid-column: 3 / span 8;
  display: flex;
  flex-wrap: wrap;

  h3 {
    font-weight: 700;
    font-size: 1em;
    color: var(--color-foreground);
  }
  h4 {
    font-weight: 600;
    margin: 0;
    font-size: 0.75em;
    color: var(--color-foreground-secondary);
  }
  .r_item {
    width: 25%;
    margin-bottom: 2vh;
  }
}

.span_quarter {
  width: 25%;
}
.span_12 {
  grid-column: span 12;
}
.span_6 {
  grid-column: span 6;
}
.span_4 {
  grid-column: span 4;
}
.span_3 {
  grid-column: span 3;
}
.span_8 {
  grid-column: 3 / span 8;
}
.next {
  text-align: center;
  padding: 5em 0 2em;
  h2 {
    font-size: 1em;
  }
  nav {
    height: 100%;
    a {
      font-size: 10em;
      font-family: $font_heading;
      font-weight: 900;
      font-size: calc(2em + 6vmin);
    }
    .activeNav {
      display: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  .content_width {
    width: $width_mobile;
  }

  header {
    padding: 1em;
  }
  .content_flex {
    width: $width_mobile;
    margin: auto;
  }
  .page_home .intro p {
    width: 100%;
  }
  nav a {
    margin-left: 0em;
    margin-top: -0.4em;
  }
  .content_grid {
    grid-template-columns: auto;
    grid-column-gap: 2%;
    padding: 0 1.5em;
  }
  .span_6,
  .span_4,
  .span_3,
  .span_8 {
    grid-column: 1 / span 12;
  }
  .intro {
    p {
      font-size: 1.25em;
      margin-bottom: 1.5em;
    }
  }
  .otherlinks a {
    font-size: 1em;
  }
  .titleblock,
  .text_left {
    grid-column: 1 / span 12;
  }

  .rapsheet {
    .r_item {
      width: 50%;
    }
  }
  .floatingnav svg.logo {
    width: 1.5em;
  }
}

@media screen and (min-width: 1600px) {
  .content_flex {
    width: $width_4k;
  }
}

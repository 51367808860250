
@font-face {
    font-family: 'Facundo';
    src: url('./Facundo-Black/font.woff2') format('woff2'), url('./Facundo-Black/font.woff') format('woff');
    font-weight:900;
    font-style:normal;
   }
@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./HelveticaNowDisplay-Blk/font.woff2') format('woff2'), url('./HelveticaNowDisplay-Blk/font.woff') format('woff');
    font-weight:900;
    font-style:normal;
    }
@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./HelveticaNowDisplay-XBd/font.woff2') format('woff2'), url('./HelveticaNowDisplay-XBd/font.woff') format('woff');
    font-weight:800;
    font-style:normal;
}
@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./HelveticaNowDisplay-Bd/font.woff2') format('woff2'), url('./HelveticaNowDisplay-Bd/font.woff') format('woff');
    font-weight:700;
    font-style:normal;
}
@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./HelveticaNowDisplay-Regular/font.woff2') format('woff2'), url('./HelveticaNowDisplay-Regular/font.woff') format('woff');
    font-weight:400;
    font-style:normal;
}
@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./HelveticaNowDisplay-Lt/font.woff2') format('woff2'), url('./HelveticaNowDisplay-Lt/font.woff') format('woff');
    font-weight:200;
    font-style:normal;
}
@font-face {
    font-family: 'MacklinSansVariable';
    src: url('./MacklinSansVariable/font.woff2') format('woff2'), url('./MacklinSansVariable/font.woff2')  format('woff');
    font-style:normal;
}
@font-face {
    font-family: 'MacklinVariable-Regular';
    src: url('./MacklinVariable-Regular/font.woff2') format('woff2'), url('./MacklinVariable-Regular/font.woff2')  format('woff');
    font-style:normal;
}
/*
Vars
*/
$color_primary: hsla(0, 0%, 20%, 1);
$color_secondary: hsla(0, 0%, 40%, 1);
$color_red: hsla(347, 98%, 43%, 1);

$width_desktop: 64%;
$width_4k: 50%;
$width_mobile: 86%;
$width_textonly: 50%;

$color_background: white;
$black: black;
$gray1: rgb(115, 119, 126);
$gray2: #f6f6f6;
$gray3: #e3e3e3;
$gray4: #ccc;

$font_title: "Facundo";
$font_heading: "HelveticaNowDisplay";
$font_paragraph: "MacklinSansVariable";
$font_display: "MacklinText-Regular";

$gradient_catalx: linear-gradient(
  120deg,
  hsla(246, 67%, 41%, 1) 0%,
  hsla(292, 57%, 64%, 1) 91%
);
$gradient_bby: linear-gradient(
  90deg,
  hsla(222, 86%, 26%, 1) 0%,
  hsla(218, 100%, 61%, 1) 50%,
  hsla(199, 100%, 65%, 1) 100%
);
$gradient_mastercard: linear-gradient(
  120deg,
  hsla(20, 100%, 41%, 1) 25%,
  hsla(45, 100%, 56%, 1) 55%
);

$gradient_general: linear-gradient(
  90deg,
  hsla(313, 100%, 50%, 1) 0%,
  hsla(360, 100%, 62%, 1) 100%
);
body {
  margin: 0;
  font-family: "HeveticaNowDisplay", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
